import React from "react"


import Jumbotron from "../components/jumbotron"
import HomePage from "../components/homepage"
import Footer from "../components/footer"

const IndexPage = () => <div id="content-wrap" className="text-center font-display">
    <Jumbotron />
    <HomePage />
    <Footer />
</div>
export default IndexPage;
