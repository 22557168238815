import React from "react"


const HomePage = () => <div id="site-content" className="content-box container mx-auto p-6 text-xl ">
    <div className="text-4xl">
        <p><strong>Hi! </strong> &nbsp;I'm Rob</p>
        <p><strong>I write code...</strong></p>
    </div>
    <p>I'm a freelance web and mobile developer located in Melbourne, Australia.</p>
    <p>I mostly make web applications and database driven websites using various combinations of Python, Django, Postgres, and React.</p>
    <p>I also develop cross-platform GUI Applications using Qt or Kivy frameworks.</p>
    <p className="text-4xl"><strong>I write about writing code...</strong></p>
    <p>I sometimes post random technical thoughts on <a href="http://www.voidynullness.net" target="_blank" rel="noopener noreferrer">my blog</a>.</p>
</div>;
export default HomePage;
