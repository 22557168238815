import React from "react"


const Jumbotron = () => <header className="content-box font-display fw-full text-white bg-black tracking-widest p-6">
    <img className="mx-auto" alt="" src="img/headshot.png" />
    <h1 className="text-4xl md:text-6xl">Robert Iwancz</h1>
    <h2 className="text-1xl md:text-2xl">Freelance Software Developer</h2>
    <p className="text-1xl md:text-1xl location" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
          <span itemprop="addressLocality">Melbourne</span> &nbsp; &bull; &nbsp;
          <span itemprop="addressCountry">Australia</span>
        </p>
</header>;
export default Jumbotron;
