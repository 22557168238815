import React from "react"


const Footer = () => <footer className="content-box w-full text-white text-sm bg-black font-footer leading-none tracking-widest p-4 h-40">
    <p className="p-2">For more about me check out my blog:
        <a href="http://www.voidynullness.net" target="_blank" rel="noopener noreferrer"> voidynullness</a>
    </p>

    <p> &copy; 2021 </p>
    <p>NULLIFIC PTY LTD</p>
    <p>ABN: 86 608 148 152</p>
</footer>;
export default Footer;
